import { IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PrintMagic extends MainMagics {
  public order: number = 10;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [
    {
      name: "machineID",
      type: "select",
      label: [{ text: this.translate.get("371_MACHINE") }],
      required: false,
      options: [],
      filter: [
        {
          key: "parameter",
          value: "10_colorfulPrinting",
        },
        {
          key: "parameter",
          value: "20_blackAndWhitePrinting",
        }
      ],
    },
    {
      name: "sizePaper",
      type: "select",
      label: [
        {
          text: this.translate.get("358_PAPER_SIZE"),
        },
      ],
      required: false,
      options: [],
    },
    {
      name: "typePaper",
      type: "select",
      label: [
        {
          text: this.translate.get("368_TYPE_OF_PAPER"),
        },
      ],
      required: false,
    },
    {
      name: "thickPaper",
      type: "select",
      label: [
        {
          text: this.translate.get("354_PAPER_THICKNESS"),
        },
      ],
      required: false,
    },
    {
      name: "twoSided",
      type: "checkbox",
      label: [
        {
          text: this.translate.get("538_TWOSIDED"),
        },
      ],
      required: false,
    }, {
      name: "colorful",
      type: "checkbox",
      label: [
        {
          text: this.translate.get("10_colorfulPrinting"),
        },
      ],
      defaultValue: true,
      required: false,
    },
  ];
  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }

  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any, base: boolean = false): Promise<IMagicMoreInfo> {
    console.log("base", base);

    const alerts: IMagicMoreInfoAlert[] = [];
    const numberOfPages: IPaperOption = this.magicsService.getNumberOfPages(design.size.width, design.size.height, parseFloat(template.print.copies as any), template);
    // const machinesPrice = this.magicsService.getMagicAllPrices("beats", magicData, parameterPrice, count, design, template);
    const width: number = design.size.width || 0;
    const height: number = design.size.height || 0;
    const copies: number = parseFloat(template.print.copies as any) || 0;

    // let basic : IMagicMessageLabelDescription[]= [];

    if (width < 1) {
      alerts.push({
        type: "error",
        text: `${this.translate.get("28_WIDTH")} - ${this.translate.get("558_REQUIRED_FIELD")}`,
      });
    }
    if (height < 1) {
      alerts.push({
        type: "error",
        text: `${this.translate.get("29_HEIGHT")} - ${this.translate.get("558_REQUIRED_FIELD")}`,
      });
    }
    if (copies < 1) {
      alerts.push({
        type: "error",
        text: `${this.translate.get("58_COPIES")} - ${this.translate.get("558_REQUIRED_FIELD")}`,
      });
    }

    if (!numberOfPages) {
      alerts.push({
        type: "error",
        text: this.translate.get("559_PAPER_SIZE_DOES_NOT_MATCH_PRINTING_SIZE"),
      });
    }

    //TODO
    //     if (typePaperData && _.includes(typePaperData.thicknesses, thicknessID)) {
    //     } else {
    //         // alerts.push(
    //         //     {
    //         //         type: 'error',
    //         //         text: `${this.translate.get("339_PAPER")} ${typePaperData.name} ${this.translate.get("550_IS_NOT_POSSIBLE_IN_PAPER_THICKNESS")} ${thickness}`
    //         //     }
    //         // )
    // }

    if (base) {
      // const minimumPrice = await this.checkDiscountedPrice(magicData, parameterPrice, count, design, template, input)
      // if (minimumPrice)
      //   alerts.push({
      //     type: "error",
      //     text: this.translate.get("677_IF_YOU_USE_WITH_A_MACHINE") + " " + minimumPrice.machineName + " " +
      //       this.translate.get("358_PAPER_SIZE") + " " + minimumPrice.sizeName + " " +
      //       this.translate.get("678_WILL_SAVE") + " " + minimumPrice.difference + this.settingService.getCurrency()
      //   });
      // debugger
    }

    return {
      basic: this.getBasicMessage(numberOfPages),
      machinesPrice: [],
      data: {
        numberOfPages,
      },
      order: this.order,
      alerts,
    };
  }

  async checkDiscountedPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any, base: boolean = false) {
    let minimumPrice: any = {}
    let currentPrice: any;
    const extensions = _.get(template, "print.extensions", []);
    console.log("extensions", extensions);
    let extensionId;
    for (const extension in extensions) {
      if (extensions[extension].input.magicName == "print") {
        extensionId = extension
        break;
      }
    }

    let currentPaperSize = _.get(template, ["print", "extensions", extensionId, "input", "values", "sizePaper"], 0);
    let currentMachine = _.get(template, ["print", "extensions", extensionId, "input", "values", "machineID"], 0);

    for (const machineID in this.settingService.machines) {
      let newTemplate: any = { ...template }
      // let colorFul = this.magicsService.getMachineColorful(template)
      // if (this.settingService.machines[machineID].parameters[colorFul]) {
      if (this.settingService.machines[machineID].parameters["10_colorfulPrinting"]) {
        _.set(newTemplate, ["print", "machineID"], machineID);
        for (let i = 0; i < this.settingService.machines[machineID].sizes.length; i++) {
          let points: number = 0
          let price: number = 0
          let paperSize = this.settingService.machines[machineID].sizes[i]
          const size = this.settingService.paper.size[paperSize]
          if ((design.size.height <= size.height) && (design.size.width <= size.width)) {
            _.set(newTemplate, ["print", "extensions", extensionId, "input", "values", "sizePaper"], paperSize);
            _.set(newTemplate, ["print", "extensions", extensionId, "input", "values", "machineID"], machineID);
            for (const extension in extensions) {
              if (extensions[extension].input.magicName !== "print") {
                const magicName = _.get(extensions, [extension, "input", "magicName"], "");
                const values = _.get(extensions, [extension, "input", "values"], "");
                const newDesign = { ...design }
                let obj: any = await this.magicsService.getMoreInfo(magicName, values, parameterPrice, count, newDesign, newTemplate, input,values);
                if (obj && obj.pointsNumber)
                  points += parseFloat(obj.pointsNumber);
                if (obj && obj.price)
                  price += parseFloat(obj.price);
              }
            }

            console.log("--points--price", points, price);

            if (machineID == currentMachine && paperSize == currentPaperSize) {
              currentPrice = price + (points * this.settingService.pricePerPoints)
            }
            if ((minimumPrice.price > (price + (points * this.settingService.pricePerPoints)) ||
              (!minimumPrice.machineID))) {
              minimumPrice.machineID = machineID
              minimumPrice.machineName = this.settingService.machines[machineID].name
              minimumPrice.paperSize = paperSize
              minimumPrice.sizeName = size.name
              minimumPrice.price = price + (points * this.settingService.pricePerPoints)
            }
          }
        }
      }
    }

    if (currentPrice != minimumPrice.price) {
      minimumPrice.difference = currentPrice - minimumPrice.price
      return minimumPrice
    }
    return false
  }


  getBasicMessage(numberOfPages) {
    if (numberOfPages) {
      return [
        {
          label: this.translate.get("339_PAPER"),
          description: numberOfPages.paper,
        },
        {
          label: this.translate.get("562_NUMBER_OF_COPIES"),
          description: numberOfPages.copies,
        },
        {
          label: this.translate.get("563_DIRECTION"),
          description: numberOfPages.orientation,
        },
        {
          label: this.translate.get("564_UNITS_ON_THE_PAGE"),
          description: numberOfPages.unitsPerPage,
        },
      ];
    } else {
      return "";
    }
  }

}
