import { Injectable } from "@angular/core";
import { ICartDesign, ICartTemplate, ITemplatePrintExtension } from "../interfaces/cart";
import { IMagicMoreInfo, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { Beats } from "../magics/beats.magic";
import { Cut } from "../magics/cut.magic";
import { Folding } from "../magics/folding.magic";
import { PaperService } from "./paper.service";
import { SettingService } from "./setting.service";
import * as _ from "lodash";
import { PrintMagic } from "../magics/print.magic";
import { PrintPoints } from "../magics/printPoints.magic";
import { OrderMagic } from "../magics/order.magic";
import { TranslateService } from "@ngx-translate/core";
import { IProductInput } from "../interfaces/products";
import { InputsService } from "./inputs.service";
import { PaperMagic } from "../magics/paper.magic";
import { TranslateProvider } from "../providers";
import { WidePrintPaperWear } from "../magics/widePrintPaperWear.magic";
import { Wastage } from "../magics/wastage.magic";
import { PagesMagic } from "../magics/pages.magic";
import { Creasing } from "../magics/creasing.magic";
import { IPaperItem } from "../interfaces/paper";
import { DataService } from "../data.service";
import { ISize } from "../interfaces/sizes";
import { PaperTypeMagic } from "../magics/paperType.magic";
import { PaperSizeMagic } from "../magics/paperSize.magic";
import { PaperThickMagic } from "../magics/paperThick.magic";
import { PaperTwoSidesMagic } from "../magics/paperTwoSides.magic";
import { PaperColorfulMagic } from "../magics/paperColorful.magic";
import { OffsetPrintingParametersMagic } from "../magics/offsetPrintingParameters.magic";
import { ProfitPercentageMagic } from "../magics/profitPercentage.magic";
import { OffsetPanelsMagic } from "../magics/offsetPanels.magic";
import { offsetVarnishMagic } from "../magics/offsetVarnish.magic";

@Injectable({
  providedIn: "root",
})
export class MagicsService {
  magicsList: IMagicsList = {
    widePrintPaperWear: {
      label: this.translateService.instant("650_WIDE_PRINT_PAPER_WEAR"),
      magicClass: new WidePrintPaperWear(this, this.settingService, this.translate, this.dataService),
    },
    wastage: {
      label: this.translateService.instant("297_PAPER_WEAR"),
      magicClass: new Wastage(this, this.settingService, this.translate, this.dataService),
    },
    beats: {
      label: this.translateService.instant("592_PRINT_BEATS"),
      magicClass: new Beats(this, this.settingService, this.translate, this.dataService),
    },
    cut: {
      label: this.translateService.instant("593_CUT"),
      magicClass: new Cut(this, this.settingService, this.translate, this.dataService),
    },
    folding: {
      label: this.translateService.instant("594_FOLDING"),
      magicClass: new Folding(this, this.settingService, this.translate, this.dataService),
    },
    creasing: {
      label: this.translateService.instant("60_creasing"),
      magicClass: new Creasing(this, this.settingService, this.translate, this.dataService),
    },
    print: {
      label: this.translateService.instant("217_PRINTING"),
      magicClass: new PrintMagic(this, this.settingService, this.translate, this.dataService),
    },
    printPoints: {
      label: this.translateService.instant("595_PONGS_PRINTING"),
      magicClass: new PrintPoints(this, this.settingService, this.translate, this.dataService),
    },
    paper: {
      label: this.translateService.instant("340_PAPER_PRICE"),
      magicClass: new PaperMagic(this, this.settingService, this.translate, this.dataService),
    },
    order: {
      label: this.translateService.instant("596_ORDER_STATION"),
      magicClass: new OrderMagic(this, this.settingService, this.dataService),
    },
    pages: {
      label: this.translateService.instant("670_PAGES"),
      magicClass: new PagesMagic(this, this.settingService, this.translate, this.dataService),
    },
    paperType: {
      label: this.translateService.instant("368_TYPE_OF_PAPER"),
      magicClass: new PaperTypeMagic(this, this.settingService, this.translate, this.dataService),
    },
    paperSize: {
      label: this.translateService.instant("358_PAPER_SIZE"),
      magicClass: new PaperSizeMagic(this, this.settingService, this.translate, this.dataService),
    },
    paperThick: {
      label: this.translateService.instant("354_PAPER_THICKNESS"),
      magicClass: new PaperThickMagic(this, this.settingService, this.translate, this.dataService),
    },
    paperTwoSides: {
      label: this.translateService.instant("538_TWOSIDED"),
      magicClass: new PaperTwoSidesMagic(this, this.settingService, this.translate, this.dataService),
    },
    paperColorful: {
      label: this.translateService.instant("10_colorfulPrinting"),
      magicClass: new PaperColorfulMagic(this, this.settingService, this.translate, this.dataService),
    },
    offsetPrintingParameters: {
      label: this.translateService.instant("702_OFFSET_PRINTING_PARAMETERS"),
      magicClass: new OffsetPrintingParametersMagic(this, this.settingService, this.translate, this.dataService),
    },
    profitPercentage: {
      label: this.translateService.instant("706_PROFIT_PERCENTAGES"),
      magicClass: new ProfitPercentageMagic(this, this.settingService, this.translate, this.dataService),
    },
    offsetPanelsMagic: {
      label: this.translateService.instant("88_offsetPanels"),
      magicClass: new OffsetPanelsMagic(this, this.settingService, this.translate, this.dataService),
    },
    offsetVarnishMagic: {
      label: this.translateService.instant("90_offsetVarnish"),
      magicClass: new offsetVarnishMagic(this, this.settingService, this.translate, this.dataService),
    }
  };


  constructor(public paperService: PaperService, private settingService: SettingService, public translateService: TranslateService, public inputsService: InputsService, private translate: TranslateProvider, public dataService: DataService) { }

  getInputs(magicName: string, extension: ITemplatePrintExtension, size: ISize): any[] {
    if (magicName && this.magicsList[magicName]) {
      return this.magicsList[magicName].magicClass.getCalculatorInputs(extension, size);
    } else {
      return [];
    }
  }

  getMagicAllPrices(magicName: string, magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design: ICartDesign, template: ICartTemplate | ICartTemplate) {
    const ans = [];
    Object.keys(this.settingService.machines).map((key) => ans.push({ [key]: this.magicsList[magicName].magicClass.getPrice(magicData, parameterPrice, count, design, template, key) }));
    return ans;
  }

  async getMoreInfo(magicName: string, magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design: ICartDesign, template: ICartTemplate | ICartTemplate, input: IProductInput, values: any, base: boolean = false): Promise<IMagicMoreInfo> {
    if (this.magicsList[magicName]) {
      try {
        return await this.magicsList[magicName].magicClass.getMoreInfo(magicData, parameterPrice, count, design, template, input, values, base);
      } catch (error) {
        console.error(error);
      }
    } else {
      return {
        machinesPrice: [],
        data: {},
      };
    }
  }

  isHiddenInput(magicName: string): boolean {
    if (this.magicsList[magicName]) {
      return this.magicsList[magicName].magicClass.isHiddenInput();
    } else {
      return false;
    }
  }

  displayPricePerUnitInPriceList(magicName: string): boolean {
    if (this.magicsList[magicName]) {
      return this.magicsList[magicName].magicClass.displayPricePerUnitInPriceList;
    } else {
      return false;
    }
  }

  getNumberOfPagesForBeats(magicData, design, template: ICartTemplate) {
    return this.magicsList["beats"].magicClass.getNumberOfPages(magicData, design.size.width, design.size.height, parseFloat(template.print.copies as any), template, design);
  }

  getMagicOrder(magicName: string) {
    return _.get(this.magicsList, [magicName, "magicClass", "order"]);
  }

  getNumberOfPages(width: number, height: number, copies: number, template: ICartTemplate, design?: ICartDesign): IPaperOption {
    const extensionPrint = this.getExtensionByName(template, "print");
    const extensionPages = this.getExtensionByName(template, "pages");
    let paperPrices: IPaperItem[] = [];

    if (extensionPrint) {
      const sizePaper = this.getPaperSize(template, design)

      if (sizePaper) {
        paperPrices = [this.settingService.getPaperData(sizePaper)];
      }
    }

    if (extensionPages) {
      const pages = _.get(extensionPages, ["input", "values", "pages"]);
      if (pages > 1) {
        width = width * 2;
        copies = ((copies / 2) * pages)
      }
    }

    if (_.isEmpty(paperPrices)) {
      return null;
    }
    let paperOptions: IPaperOption[] = [];

    paperPrices
      .filter((item) => item)
      .map((paper) => {
        paperOptions.push(this.getPaperOption(paper, "portrait", "toWidth", width, height, copies));
        paperOptions.push(this.getPaperOption(paper, "landscape", "toWidth", width, height, copies));
        paperOptions.push(this.getPaperOption(paper, "portrait", "toHeight", width, height, copies));
        paperOptions.push(this.getPaperOption(paper, "landscape", "toHeight", width, height, copies));
      });
    paperOptions = _.filter(paperOptions, (option) => option.copies > 0);
    const minPaperOption = _.minBy(paperOptions, "copies");
    return minPaperOption;
  }

  getExtensionByName(template: ICartTemplate, name: string): IMagicsInput {
    let input;
    for (const extensionID in template.print.extensions) {
      if (template.print.extensions[extensionID].input.magicName === name) {
        input = template.print.extensions[extensionID];
      }
    }
    return input;
  }

  getPaperOption(paperData: IPaperItem, orientation: "portrait" | "landscape", unitOrientation: "toWidth" | "toHeight", width: number, height: number, copies: number): IPaperOption {
    width = width;
    height = height;
    let cyx = 0;
    let cxy = 0;
    let pyx = 0;
    let pxy = 0;

    switch (true) {
      case orientation == "portrait" && unitOrientation == "toWidth":
        cyx = width;
        cxy = height;
        pyx = paperData.height;
        pxy = paperData.width;
        break;
      case orientation == "portrait" && unitOrientation == "toHeight":
        cyx = height;
        cxy = width;
        pyx = paperData.height;
        pxy = paperData.width;
        break;
      case orientation == "landscape" && unitOrientation == "toWidth":
        cyx = width;
        cxy = height;
        pyx = paperData.width;
        pxy = paperData.height;
        break;
      case orientation == "landscape" && unitOrientation == "toHeight":
        cyx = height;
        cxy = width;
        pyx = paperData.width;
        pxy = paperData.height;
        break;

      default:
        break;
    }
    const toWidth: number = orientation === "landscape" ? pyx / cyx : pyx / cxy;
    const toHeight: number = orientation === "landscape" ? pxy / cxy : pxy / cyx;

    if (toWidth < 1 || toHeight < 1) {
      return {
        paper: paperData.name,
        unitsPerPage: 0,
        orientation: orientation,
        price: 0,
        copies: 0,
        unitOrientation: unitOrientation,
        paperData,
      };
    }

    const unitsPerPage: number = Math.floor(toWidth) * Math.floor(toHeight);
    const printCopies: number = Math.ceil(copies / unitsPerPage);
    return {
      paper: paperData.name,
      unitsPerPage: unitsPerPage,
      orientation: orientation,
      unitOrientation: unitOrientation,
      price: 0,
      copies: printCopies,
      x: Math.floor(toWidth),
      y: Math.floor(toHeight),
      paperData,
    };
  }

  getMachineID(template: ICartTemplate, design?: ICartDesign, values?) {
    if (values && values.machineID)
      return values.machineID
    else {
      const extensionPrint = this.getExtensionByName(template, 'print');
      const machineID = _.get(extensionPrint, ['input', 'values', 'machineID'])
      if (machineID) {
        return machineID;
      }

      else {
        if (!design || !design.size) {
          design = { size: {} }
        }
        const defaultMachine = this.dataService.getDefaultValue('machineID', design.size)
        if (defaultMachine) {
          return defaultMachine
        }
        let machineId;
        for (const machineID in this.settingService.machines) {
          const machine = this.settingService.machines[machineID];
          if (machine.parameters["10_colorfulPrinting"] || machine.parameters["20_blackAndWhitePrinting"]) {
            machineId = machineID
            break;
          }
        }
        return machineId;
      }
    }

  }

  getDefualtMachineID(values, ability: any) {
    if (values && values.machineID)
      return values.machineID
    else {
      let machineId;
      for (const machineID in this.settingService.machines) {
        const machine = this.settingService.machines[machineID];
        if (machine.parameters[ability]) {
          machineId = machineID
          break;
        }
      }
      return machineId;
    }
  }

  getPaperSize(template: ICartTemplate, design) {
    const extensionPrint = this.getExtensionByName(template, 'print');
    const paperSize = _.get(extensionPrint, ["input", "values", "sizePaper"])
    if (paperSize) {
      return paperSize;
    }
    else {
      const extensionPaperSize = this.getExtensionByName(template, "paperSize");
      const paperSize = _.get(extensionPaperSize, "input.values.sizePaper", null);
      if (paperSize)
        return paperSize
      else {
        const machineID = this.getMachineID(template, design)
        if (!design || !design.size) {
          design = { size: {} }
        }
        const selectSizePaperInput: any = this.inputsService.getSelectSizePaperInput(machineID, design.size)
        return selectSizePaperInput.defaultValue
      }
    }
  }

  getPaperThick(template: ICartTemplate, design) {
    const extensionPrint = this.getExtensionByName(template, 'print');
    const paperThick = _.get(extensionPrint, ["input", "values", "thickPaper"], 0)
    if (paperThick) {
      return paperThick;
    }
    else {
      const extensionPaperThick = this.getExtensionByName(template, "paperThick");
      const paperThick = _.get(extensionPaperThick, "input.values.thickPaper", null);
      if (paperThick)
        return paperThick
      else {
        const machineID = this.getMachineID(template, design)
        if (!design || !design.size) {
          design = { size: {} }
        }
        const selectThicknessePaperInput: any = this.inputsService.getSelectThicknessPaperInput(machineID, design.size)
        return selectThicknessePaperInput.defaultValue
      }

    }
  }

  getPaperType(template: ICartTemplate, design) {
    const extensionPrint = this.getExtensionByName(template, 'print');
    const paperType = _.get(extensionPrint, ["input", "values", "typePaper"])
    if (paperType) {
      return paperType;
    }
    else {
      const extensionPaperType = this.getExtensionByName(template, "paperType");
      const paperType = _.get(extensionPaperType, "input.values.typePaper", null);
      if (paperType) {
        return paperType
      }
      else {
        const machineID = this.getMachineID(template, design)
        if (!design || !design.size) {
          design = { size: {} }
        }
        const selectTypePaperInput: any = this.inputsService.getSelectTypePaperInput(machineID, design.size)
        return selectTypePaperInput.defaultValue
      }

    }
  }

  getMachineColorful(template: ICartTemplate, design) {
    const extensionPrint = this.getExtensionByName(template, 'print');
    const colorful = _.get(extensionPrint, ['input', 'values', 'colorful'])
    if (colorful) {
      return "10_colorfulPrinting";
    }
    const machineID = this.getMachineID(template, design)
    if (!design || !design.size) {
      design = { size: {} }
    }
    const selectColorfulInput: any = this.inputsService.getCheckboxColorfulInput(machineID, design.size)
    if (selectColorfulInput.defaultValue) {
      return "10_colorfulPrinting";
    }
    return "20_blackAndWhitePrinting";

  }

  getTwoSided(template: ICartTemplate, design) {
    const extensionPrint = this.getExtensionByName(template, 'print');
    const twoSided = _.get(extensionPrint, ["input", "values", "twoSided"])
    if (twoSided) {
      return twoSided;
    }
    else {
      const extensionPaperSize = this.getExtensionByName(template, "paperTwoSides");
      const twoSided = _.get(extensionPaperSize, "input.values.twoSided", null);
      if (twoSided)
        return twoSided
      else {
        const machineID = this.getMachineID(template, design)
        if (!design || !design.size) {
          design = { size: {} }
        }
        const selectTwoSidesInput: any = this.inputsService.getCheckboxTwoSidesInput(machineID, design.size)
        return selectTwoSidesInput.defaultValue
      }
    }

  }

  extensionPrint(template: ICartTemplate) {
    const extensionPrint = this.getExtensionByName(template, 'print');
    if (extensionPrint)
      return true;
    return false;
  }

  getBeatsData(template: ICartTemplate, copies: number, design) {
    let price = 0
    const machineID = this.getMachineID(template)
    const colorfulParams = this.getMachineColorful(template, design)
    const priceBeat = _.get(this.settingService.machines, [machineID, 'parameters', colorfulParams, 'beat'], 0)
    const twoSided = this.getTwoSided(template, design)
    if (twoSided) {
      copies = copies * 2;
    }
    price = priceBeat * copies;
    return {
      price,
      twoSided,
      priceBeat,
      copies
    }

  }

  getPaperPriceByExtensionPrint(template, design): number {
    let width = 0, height = 0, thickness = 0;

    const sizePaperID = this.getPaperSize(template, design)
    if (sizePaperID) {
      const paperData = this.settingService.getPaperData(sizePaperID)
      if (paperData) {
        width = (paperData.width / 100);
        height = (paperData.height / 100);
      }
    }
    const typePaperID = this.getPaperType(template, design)
    const typePaperData = this.settingService.getPaperPrice(typePaperID);
    const thicknessID = this.getPaperThick(template, design)
    if (thicknessID) {
      const thicknessData = this.settingService.getThicknessData(thicknessID)
      if (thicknessData) {
        thickness = thicknessData;
      }
    }
    let customPrice = _.get(this.settingService.paper, ['type', typePaperID, 'price', thicknessID, 'custom', sizePaperID])
    if (customPrice) {
      return customPrice;
    } else {
      const priceByTone = this.settingService.getPricePerTone(typePaperData, thicknessID, (width * 100), (height * 100))
      const singlePagePrice = this.settingService.getPaperAmount(width, height, thickness, priceByTone);
      return singlePagePrice;
    }
  }

  getPricingMethod(machineID: any, ability: any) {
    const machine = _.get(this.settingService.machines, [machineID])
    const calcPrice = _.get(machine, ['parameters', ability, 'calcPrice'], false)
    return calcPrice
  }

  getPoints(machineID: any, ability: any, copies: any, mainColumn: any, secoundColumn: any = "pointsAmount") {
    const pointsAmount = _.get(this.settingService.machines, [machineID, 'parameters', ability, 'printPoints'], [])
    const pointsAmountSort = pointsAmount.sort((a, b) => parseFloat(b[mainColumn]) - parseFloat(a[mainColumn]))
    let amountIndex = pointsAmountSort.findIndex(amountLine => copies >= parseFloat(amountLine[mainColumn]));
    if (amountIndex < 0 && pointsAmountSort.length > 0) {
      amountIndex = pointsAmountSort.length - 1
    }
    let pointAmount = 0
    let pointsNumber = 0;
    let minCopies = 0
    if (pointsAmountSort[amountIndex] && pointsAmountSort[amountIndex - 1]) {
      const current = pointsAmountSort[amountIndex][secoundColumn] * copies;
      const next = pointsAmountSort[amountIndex - 1][secoundColumn] * pointsAmountSort[amountIndex - 1][mainColumn];
      if (current > next) {
        pointsNumber = next
        minCopies = pointsAmountSort[amountIndex - 1][mainColumn]
        pointAmount = pointsAmountSort[amountIndex - 1][secoundColumn];
      } else {
        pointsNumber = current
        pointAmount = pointsAmountSort[amountIndex][secoundColumn];
      }
    } else if (pointsAmountSort[amountIndex]) {
      pointsNumber = pointsAmountSort[amountIndex][secoundColumn] * copies;
      pointAmount = pointsAmountSort[amountIndex][secoundColumn]
    }
    let minimumPoints = 0
    if (pointsAmountSort.length > 0) {
      const largeIndex = pointsAmountSort.length - 1
      minimumPoints = pointsAmountSort.length > 0 ? pointsAmountSort[largeIndex][secoundColumn] * pointsAmountSort[largeIndex][mainColumn] : 0
    }

    return {
      minimumPoints,
      pointsNumber,
      pointAmount,
      minCopies
    }
  }
}
