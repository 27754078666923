import { IMagicMoreInfo, IMagicsInput, IMagicsParams, IPaperOption } from '../interfaces/magics';
import { MainMagics } from './mainMagics';
import * as _ from 'lodash';
import { MagicsService } from '../services/magics.service';
import { ICartDesign, ICartTemplate } from '../interfaces/cart';
import { SettingService } from '../services/setting.service';
import { IProductInput } from '../interfaces/products';
import { DataService } from '../data.service';


export class OrderMagic extends MainMagics {
    data: any = {};
    public order: number = 20;

    calculatorInputs: IMagicsInput[] = [];

    constructor(public magicsService: MagicsService, private settingService: SettingService, public dataService: DataService) {
        super(magicsService, dataService);
        this.updateCalculatorInputs();
    }

    async updateCalculatorInputs() {
        this.calculatorInputs = [
            {
                label: [{
                    text: ''
                    // text: await this.magicsService.translateService.get('423_ORDER_TYPE').toPromise()

                }],
                type: 'select',
                options: [
                    { label: await this.magicsService.translateService.get('424_STANDARD_ORDER').toPromise(), value: 'standard' },
                    { label: await this.magicsService.translateService.get('425_COUNTER_FROM_A_COMPUTER').toPromise(), value: 'counterComputer' },
                    { label: await this.magicsService.translateService.get('426_DIRECT_COUNTER').toPromise(), value: 'counter' },
                ],
                name: 'orderType',
                defaultValue: 'standard',
            }
        ];

    }


    getPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, machinesKey?: string): number {
        return 0;
    }


    getBasicMessage(magicData: IMagicsParams, width: number, height: number, copies: number, template: ICartTemplate) {

    }

    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
        const orderType = _.get(template, ['print', 'extensions', input.id, 'input', 'values', 'orderType'], 'standard')
        const quantityOfProducts = 1;
        let pointsNumber = 0;
        let min = 0;
        let perProduct = 0;

        switch (orderType) {
            case 'standard':
                min = _.get(this.settingService.general, 'settingPoints.minimumStandardOrderPoints', 0);
                perProduct = _.get(this.settingService.general, 'settingPoints.pointsEachProductRegularOrder', 0);
                break;
            case 'counterComputer':
                min = _.get(this.settingService.general, 'settingPoints.minimumManualOrderPoints', 0);
                perProduct = _.get(this.settingService.general, 'settingPoints.pointsEachProductManualOrder', 0);
                break;
            case 'counter':
                min = _.get(this.settingService.general, 'settingPoints.minimumCounterOrderPoints', 0);
                perProduct = _.get(this.settingService.general, 'settingPoints.pointsEachProductOrderCounter', 0);
                break;
        }

        pointsNumber = (perProduct * quantityOfProducts) > min ? (perProduct * quantityOfProducts) : min;

        return {
            basic: '',
            machinesPrice: [],
            pointsNumber: pointsNumber,
            order: this.order,
        };
    }



    isHiddenInput(): boolean {
        return false;
    }


}
