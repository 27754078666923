import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicsInput, IMagicsParams } from '../interfaces/magics';
import { MainMagics } from './mainMagics';
import * as _ from 'lodash';
import { MagicsService } from '../services/magics.service';
import { ICartDesign, ICartTemplate } from '../interfaces/cart';
import { SettingService } from '../services/setting.service';
import { IProductInput } from '../interfaces/products';
import { TranslateProvider } from '../providers';
import { DataService } from '../data.service';


export class Folding extends MainMagics {
    data: any = {};
    calculatorInputs: IMagicsInput[] = [
        {
            name: "machineID",
            type: "select",
            label: [{ text: this.translate.get("371_MACHINE") }],
            required: false,
            options: [],
            filter: [
                {
                    key: "parameter",
                    value: "40_folding",
                },
            ],
        },
        {
            name: "amountFolds",
            type: "select",
            label: [{ text: this.translate.get("668_THE_AMOUNT_OF_FOLDS_IN_THE_UNIT") }],
            required: false,
            options: [{
                label: `0 ${this.translate.get("669_FOLDS")}`,
                value: "0",
            },
            {
                label: `1 ${this.translate.get("40_folding")}`,
                value: "1",
            },
            {
                label: `2 ${this.translate.get("669_FOLDS")}`,
                value: "2",
            },
            {
                label: `3 ${this.translate.get("669_FOLDS")}`,
                value: "3",
            },
            {
                label: `4 ${this.translate.get("669_FOLDS")}`,
                value: "4",
            },
            {
                label: `5 ${this.translate.get("669_FOLDS")}`,
                value: "5",
            }
            ],
            defaultValue: "0"
        }
    ];

    constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }


    getPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, machinesKey?: string): number {
        const minimumPrice = _.get(magicData, 'minimumPrice', 0);
        const pricePerMeter = _.get(magicData, 'pricePerMeter', 0);
        const cuttingMeter = this.getCuttingMeter(design, template);
        const max = _.max([parseFloat(minimumPrice), (cuttingMeter * pricePerMeter)]);
        return this.settingService.machines[machinesKey] && this.settingService.machines[machinesKey].parameters.print ? max + (cuttingMeter * this.settingService.machines[machinesKey].pulsePrice) : max;
    }

    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
        const machinesPrice = this.magicsService.getMagicAllPrices('folding', magicData, parameterPrice, count, design, template);
        // const machineID = this.magicsService.getMachineID(template, design, values);
        const machineID = this.magicsService.getDefualtMachineID(values, '40_folding');

        let price = 0
        const amountFolds = _.get(values, "amountFolds", null);
        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        const copies = _.get(objectCopies, ["copies"], 0);
        const x = _.get(objectCopies, ["x"], 0);
        if (objectCopies) {
            let calcPoints = amountFolds * x
            let basic: IMagicMessageLabelDescription[] = [];
            let points = 0;
            let obj = this.magicsService.getPoints(machineID, "40_folding", copies, "amountOfFolds")
            points = obj.pointsNumber
            const pointAmount = obj.pointAmount
            const minPoints = obj.minimumPoints

            let pointsNumber = calcPoints * points

            if (pointsNumber >= minPoints) {
                if (obj.minCopies) {
                    const pointsPerPrint = Math.round((obj.minCopies / copies) * 100) / 100
                    const countPoints = Math.round(((pointAmount * calcPoints) / (obj.minCopies / copies)) * 100) / 100
                    basic.push({
                        label: `${this.translate.get("567_PONGS_FOR_ANY_PRINTING")}`,
                        description: `${pointsPerPrint}
                        (${this.translate.get("717_BY_THE_CALCULATION_OF")} ${countPoints} ${this.translate.get("429_POINTS")} ${this.translate.get("198_FOR")} ${obj.minCopies} ${this.translate.get("58_COPIES")})`,
                    });
                }
                basic.push({
                    label: `${this.translate.get("666_THE_AMOUNT_OF_FOLDS")}`,
                    description: calcPoints * copies,
                });
                basic.push({
                    label: `${this.translate.get("547_PRICE_PER_POINT")}`,
                    description: pointAmount,
                });
            }
            else {
                basic.push({
                    label: `${this.translate.get("641_MINIMUM_POINTS")}`,
                    description: minPoints,
                });
                if (amountFolds != "0")
                    pointsNumber = minPoints
            }

            if (this.magicsService.getPricingMethod(machineID, '40_folding')) {
                price = pointsNumber * this.settingService.pricePerPoints
                pointsNumber = 0
            }
            return {
                basic: basic,
                machinesPrice,
                data: {},
                pointsNumber: pointsNumber,
                price: price
            };
        }
    }


    isHiddenInput(): boolean {
        return false;
    }


    getCuttingMeter(design: ICartDesign, template: ICartTemplate): number {
        const copies = _.get(template, ['print', 'copies'], 0);
        const width = _.get(design, ['size', 'width'], 0) / 1000;
        const height = _.get(design, ['size', 'height'], 0) / 1000;
        return +(((width * 2) + (height * 2)) * copies).toFixed(2);
    }

}
