import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class OffsetPanelsMagic extends MainMagics {
  calculatorInputs: IMagicsInput[] = [
    {
      name: "machineID",
      type: "select",
      label: [{ text: this.translate.get("371_MACHINE") }],
      required: false,
      options: [],
      filter: [
        {
          key: "parameter",
          value: "84_offsetColorfulPrinting",
        },
        {
          key: "parameter",
          value: "86_offsetBlackAndWhitePrinting",
        }
      ]
    }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];
    let basic: IMagicMessageLabelDescription[] = [];
    const machineID = _.get(values, "machineID", null);
    const machine = _.get(this.settingService.machines, [machineID])
    const extensions = _.get(template, ['print', 'extensions'])
    let price = 0;
    let flag = 0

    for (const extensionID in extensions) {
      const magicName = _.get(extensions, [extensionID, 'input', 'magicName'], null)
      if (magicName == "offsetPrintingParameters") {
        const values = _.get(extensions, [extensionID, 'input', 'values'], null)
        if (values && values.amountOfColor) {
          flag = 1;
          let obj = this.magicsService.getPoints(machineID, "88_offsetPanels", values.amountOfColor, "boardsAmount", "pricePerUnit")
          let points = obj.pointsNumber
          const pointAmount = obj.pointAmount
          const minPoints = obj.minimumPoints

          if (pointAmount > 0)
            if (values.amountOfColor == 1 || minPoints > points) {
              basic.push({
                label: `${this.translate.get("332_MINIMUM_PRICE")}`,
                description: minPoints,
              });
              price = minPoints
            }
            else {
              price = values.amountOfColor * pointAmount
              if (obj.minCopies) {
                price = obj.minCopies * pointAmount
                const pointsPerPrint = Math.round((obj.minCopies / values.amountOfColor) * 100) / 100
                const countPoints = Math.round(((pointAmount * values.amountOfColor) / (obj.minCopies)) * 100) / 100
                basic.push({
                  label: `${this.translate.get("567_PONGS_FOR_ANY_PRINTING")}`,
                  description: `${pointsPerPrint}
                    (${this.translate.get("717_BY_THE_CALCULATION_OF")} ${countPoints} ${this.translate.get("429_POINTS")} ${this.translate.get("198_FOR")} ${obj.minCopies} ${this.translate.get("58_COPIES")})`,
                });
              }
              basic.push({
                label: `${this.translate.get("705_THE_AMOUNT_OF_BOARDS")}`,
                description: values.amountOfColor,
              });
              basic.push({
                label: `${this.translate.get("366_PRICE_PER_UNIT")}`,
                description: pointAmount,
              });
            }
        }
        else {
          alerts.push({
            type: "error",
            text: `${this.translate.get("88_offsetPanels")} ${this.translate.get("695_IS_NOT_SUPPORTED_IN_THE_MACHINE")} ${machine.name}`,
          });
        }
        break
      }
    }

    if (!flag)
      alerts.push({
        type: "error",
        text: `${this.translate.get("702_OFFSET_PRINTING_PARAMETERS")}  ${this.translate.get("87_OFF")}`,
      });

    return {
      basic: basic,
      data: {},
      price: price,
      alerts
    };
  }
}
