import * as _ from "lodash";
import { toNumber } from "lodash";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IMagicMoreInfo, IMagicsInput, IMagicsParams } from "../interfaces/magics";
import { IProductInput } from "../interfaces/products";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { MainMagics } from "./mainMagics";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PrintPoints extends MainMagics {
    constructor(public magicsService: MagicsService, private settingService: SettingService, public translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }
    input: IMagicsInput[] = [
        {
            name: 'points',
            type: 'number',
            label: [{ text: 'נקודות' }],
            required: false,
        }
    ]


    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
        let copies;
        const basic = [];
        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        copies = _.get(objectCopies, ['copies'], 0);
        let price = 0
        let pointsNumber = 0;
        if (objectCopies && copies) {
            const machineID = this.magicsService.getMachineID(template, design);
            const colorfulParams = this.magicsService.getMachineColorful(template, design)
            const twoSided = this.magicsService.getTwoSided(template, design)

            if (copies && twoSided) {
                const extensionPages = this.getExtensionByName(template, "pages");
                const pages = _.get(extensionPages, ["input", "values", "pages"], 1);
                if (pages == 1) {
                    copies = copies * 2
                }
            }
            let obj = this.magicsService.getPoints(machineID, colorfulParams, copies, "pagesAmount")
            let pointAmount = obj.pointAmount
            pointsNumber = obj.pointsNumber
            const minimumPoints = obj.minimumPoints
            if (minimumPoints > pointsNumber) {
                pointsNumber = minimumPoints
                basic.push({
                    label: this.translate.get('641_MINIMUM_POINTS'),
                    description: minimumPoints,
                })
            }
            else {
                pointsNumber = pointsNumber
                if (obj.minCopies) {
                    const pointsPerPrint = Math.round((obj.minCopies / copies) * 100) / 100
                    const countPoints = Math.round(((pointAmount) / (obj.minCopies / copies)) * 100) / 100
                    basic.push({
                        label: `${this.translate.get("567_PONGS_FOR_ANY_PRINTING")}`,
                        description: `${pointsPerPrint}
                        (${this.translate.get("717_BY_THE_CALCULATION_OF")} ${countPoints} ${this.translate.get("429_POINTS")} ${this.translate.get("198_FOR")} ${obj.minCopies} ${this.translate.get("58_COPIES")})`,
                    });
                }
                else {
                    basic.push({
                        label: twoSided ? this.translate.get('568_PRINTS') + " " + this.translate.get('566_PRINTS_INCLUDING_BILATERAL') : this.translate.get('568_PRINTS'),
                        description: copies,
                    })
                }
            }

            basic.push({
                label: this.translate.get('app.label.total') + " " + this.translate.get('567_PONGS_FOR_ANY_PRINTING'),
                description: pointAmount,
            })

            const typePaperID = this.magicsService.getPaperType(template, design)
            const typePaperData = this.settingService.getPaperPrice(typePaperID);

            if (typePaperData.addPercentagePoints) {
                const extraForPaperType = Math.round((pointsNumber * (typePaperData.addPercentagePoints / 100)) * 100) / 100
                pointsNumber = pointsNumber + extraForPaperType
                basic.push({
                    label: this.translate.get("639_EXTENSION") + ' ' + extraForPaperType + ' ' + this.translate.get("640_POINTS_FOR_PAPER") + ' ' + typePaperData.name,
                    description: `${typePaperData.addPercentagePoints}%`
                })
            }

            const ability = this.magicsService.getMachineColorful(template, design)
            if (this.magicsService.getPricingMethod(machineID, ability)) {
                price = pointsNumber * this.settingService.pricePerPoints
                pointsNumber = 0
            }
            return {
                basic,
                pointsNumber,
                price,
                data: {},
            };
        }
    }

}