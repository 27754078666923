import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { IPaperItem } from "../interfaces/paper";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PagesMagic extends MainMagics {
  order: number = 12;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [
    {
      name: "pages",
      type: "number",
      required: false,
    }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }

  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];
    const pages = _.get(values, "pages", 0);

    if (pages % 2 != 0) {
      alerts.push({
        type: "error",
        text: `${this.translate.get("724_UNABLE_TO_CHOOSE_A_COUPLE_NUMBER")}`,
      });
    }
    else {
      if (pages % 4 != 0) {
        alerts.push({
          type: "error",
          text: `${this.translate.get("725_NOTE_NO_NUMBER_OF_MULTIPLES_IN_MULTIPLES__MUST_BE_CHECKED_THAT_CAN_BE_ATTACHED_TO_A_SINGLE_PAGE_BOOKLET")}`,
        });
      }
    }

    return {
      machinesPrice: [],
      order: this.order,
      alerts
    };
  }
}
