import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicsInput, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { SettingService } from "../services/setting.service";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class Beats extends MainMagics {
  data: any = {};

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }

  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any, base: boolean = false): Promise<IMagicMoreInfo> {
    let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
    let copies = _.get(objectCopies, ["copies"], 0);

    let basic: IMagicMessageLabelDescription[] = [];
    let price = 0;
    if (objectCopies) {
      const beatsData = this.magicsService.getBeatsData(template, copies, design);
      price = beatsData.price;
      basic.push({
        label: this.translate.get("655_A_BEAT_PRICE"),
        description: beatsData.priceBeat,
      });
      basic.push({
        label: beatsData.twoSided ? this.translate.get('568_PRINTS') + " " + this.translate.get('566_PRINTS_INCLUDING_BILATERAL') : this.translate.get('568_PRINTS'),
        description: beatsData.copies,
      });
    }
    return {
      basic,
      machinesPrice: [],
      data: {},
      price,
    };
  }

  isHiddenInput(): boolean {
    return false;
  }
}
