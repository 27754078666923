import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class ProfitPercentageMagic extends MainMagics {
  public order: number = 10;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [
    {
      name: "profitPercentage",
      type: "number",
      label: [{ text: this.translate.get("706_PROFIT_PERCENTAGES") }],
      required: false,

    }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];
    let price: number = 0
    let basic: IMagicMessageLabelDescription[] = [];
    const profitPercentage = _.get(values, "profitPercentage", 0);
    if (profitPercentage > 0) {
      const extensions = _.get(template, ['print', 'extensions'])
      let points: number = 0
      for (const extensionID in extensions) {
        const moreInfo = _.get(extensions, [extensionID, 'moreInfo'], null)
        const magicName = _.get(extensions, [extensionID, 'input', 'magicName'], null)
        if (magicName != "profitPercentage") {
          if (moreInfo && moreInfo.pointsNumber)
            points += parseFloat(moreInfo.pointsNumber);
          if (moreInfo && moreInfo.price)
            price += parseFloat(moreInfo.price);
        }

      }
      price = price + (points * this.settingService.pricePerPoints)
      price= price*(parseFloat((profitPercentage) as any) /100)
    }



    return {
      basic: basic,
      data: {},
      price: price,
      alerts,
      order: 999
    };

  }
}
