import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicsInput, IMagicsParams } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { SettingService } from "../services/setting.service";
import { TranslateProvider } from "../providers";
import { IProductInput } from "../interfaces/products";
import { DataService } from "../data.service";

export class Wastage extends MainMagics {
  data: any = {};
  inputs: IMagicsInput[] = [];

  constructor(magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }

  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const extensionPrint = this.magicsService.extensionPrint(template)
    let basic: IMagicMessageLabelDescription[] = [];
    let price = 0;

    if (extensionPrint) {
      const wearPaper: number = await this.getWearPaper(basic, parameterPrice, count, design, template, input);

      if (wearPaper) {
        const beatsData = this.magicsService.getBeatsData(template, wearPaper, design);
        price += beatsData.price;
        basic.push({
          label: this.translate.get("655_A_BEAT_PRICE"),
          description: beatsData.priceBeat,
        });

        if (beatsData.twoSided) {
          basic.push({
            label: this.translate.get('568_PRINTS') + " " + this.translate.get('566_PRINTS_INCLUDING_BILATERAL'),
            description: beatsData.copies,
          });
        }

        const singlePagePrice = this.magicsService.getPaperPriceByExtensionPrint(template, design);
        price += singlePagePrice * wearPaper;

        basic.push({
          label: this.translate.get("340_PAPER_PRICE"),
          description: singlePagePrice,
        });
        basic.push({
          label: this.translate.get("58_COPIES"),
          description: wearPaper,
        });
      }
    }

    return {
      basic,
      data: {},
      price,
    };
  }

  async getWearPaper(basic, parameterPrice, count, design, template, input): Promise<number> {
    let copies;
    let amountWear: number = 0;

    let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
    copies = _.get(objectCopies, ["copies"], 0);

    //print
    amountWear += this.getWearPaperPrintingStation(copies, basic);

    //cut
    amountWear += this.getWearPaperCutStation(template, copies, basic, design);

    return amountWear;
  }

  getWearPaperPrintingStation(copies, basic): number {
    let amountWear: number = 0;
    switch (true) {
      case copies > 10000:
        amountWear = Math.ceil(copies * 0.005);
        break;
      case copies > 1000:
        amountWear = Math.ceil(copies * 0.01);
        break;
      case copies > 100:
        amountWear = Math.ceil(copies * 0.02);
        break;
      case copies > 10:
        amountWear = 2;
        break;
      case copies > 5:
        amountWear = 2;
        break;
    }

    if (amountWear) {
      basic.push({
        label: this.translate.get("656_QUANTITY_OF_PRINTING_STATION"),
        description: amountWear,
      });
    }
    return amountWear;
  }

  getWearPaperCutStation(template, copies, basic, design): number {
    let amountWear: number = 0;
    const machineID = this.magicsService.getMachineID(template, design)
    const percentageOfWearPrints = _.get(this.settingService.machines, [machineID, "parameters", "50_cut", "percentageOfWearPrints"], 0);
    amountWear += Math.ceil(copies * (percentageOfWearPrints / 100));
    if (amountWear) {
      basic.push({
        label: this.translate.get("657_QUANTITY_OF_CUTTING_STATION"),
        description: amountWear,
      });
    }

    return amountWear;
  }

  isHiddenInput(): boolean {
    return false;
  }
}
