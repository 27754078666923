import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IDynamicInput } from '../../interfaces/general';
import { TranslateProvider } from '../../providers';
import { CustomerService } from '../../services/customer.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-new-client-modal',
  templateUrl: './new-client-modal.component.html',
  styleUrls: ['./new-client-modal.component.scss'],
})
export class NewClientModalComponent implements OnInit {
  inputs: IDynamicInput;
  mail: IDynamicInput;
  img: string = environment.plus;
  properties;
  constructor(private concatInformation: CustomerService, private translate: TranslateProvider, public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.properties = {
      activity: true,
      name: '',
      id: '',
      dealerNumber: '',
      note: '',
      concat: {
        city: '',
        country: '',
        mail: '',
        person: '',
        phone: '',
        street: '',
        telephone: ''
      }
    };
    this.inputs = this.concatInformation.getContactInformation();
  }

  save() {
    this.concatInformation.addCostumer(this.properties);
    this.modalController.dismiss();
  }
}
