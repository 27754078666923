import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class offsetVarnishMagic extends MainMagics {
  calculatorInputs: IMagicsInput[] = [
    {
      name: "machineID",
      type: "select",
      label: [{ text: this.translate.get("371_MACHINE") }],
      required: false,
      options: [],
      filter: [
        {
          key: "parameter",
          value: "90_offsetVarnish",
        }
      ]
    },
    {
      name: "varnishType",
      type: "select",
      label: [{ text: this.translate.get("708_TYPES_OF_VARNISH") }],
      required: true,
      options: [
        {
          label: `${this.translate.get("654_WITH_NO")}`,
          value: "withNo",
        },
        {
          label: `${this.translate.get("709_BRILLIANT")}`,
          value: "brilliant",
        },
        {
          label: `${this.translate.get("710_MATTE")}`,
          value: "matte",
        },
        {
          label: `${this.translate.get("711_SAMI_MAT")}`,
          value: "samiMat",
        }],
      filter: [],
    }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];
    let basic: IMagicMessageLabelDescription[] = [];
    const machineID = _.get(values, "machineID", null);
    const machine = _.get(this.settingService.machines, [machineID])
    let varnishTypes = _.get(machine, ['parameters', '90_offsetVarnish', 'varnishTypes'], null)
    let varnishIndex = -1
    if (varnishTypes)
      varnishIndex = varnishTypes.findIndex((type) => type == values.varnishType)
    let pointsNumber = 0;
    let price = 0;


    if (!machineID && values && values.varnishType && values.varnishType != "withNo") {
      alerts.push({
        type: "error",
        text: `${this.translate.get("714_NOT_SELECTED_MACHINE_TO_VARNISH")}`,
      });
    }

    else {
      if (varnishIndex < 0) {
        if (values && values.varnishType && values.varnishType != "withNo") {
          alerts.push({
            type: "error",
            text: `${this.translate.get("371_MACHINE")} ${machine.name} ${this.translate.get("713__DOES_NOT_SUPPORT_THE_TYPE_OF_VARNISH")} ${values.varnishType}`,
          });
        }
      }
      else {
        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        let copies = _.get(objectCopies, ["copies"], 0);
        const twoSided = this.magicsService.getTwoSided(template, design)
        if (twoSided) {
          copies = copies * 2;
        }
        if (objectCopies) {
          let obj = this.magicsService.getPoints(machineID, "90_offsetVarnish", copies, "pagesAmount")
          pointsNumber = obj.pointsNumber
          const pointAmount = obj.pointAmount
          const minPoints = obj.minimumPoints

          if (pointsNumber >= minPoints) {
            if (obj.minCopies) {
              const pointsPerPrint = Math.round((obj.minCopies / copies) * 100) / 100
              const countPoints = Math.round(((pointAmount) / (obj.minCopies / copies)) * 100) / 100
              basic.push({
                  label: `${this.translate.get("567_PONGS_FOR_ANY_PRINTING")}`,
                  description: `${pointsPerPrint}
                  (${this.translate.get("717_BY_THE_CALCULATION_OF")} ${countPoints} ${this.translate.get("429_POINTS")} ${this.translate.get("198_FOR")} ${obj.minCopies} ${this.translate.get("58_COPIES")})`,
              });
          }
            basic.push({
              label: twoSided ? `${this.translate.get("572_THE_AMOUNT_OF_PAGES")}` + " " + this.translate.get('566_PRINTS_INCLUDING_BILATERAL') :
                `${this.translate.get("572_THE_AMOUNT_OF_PAGES")}`,
              description: copies,
            });
            basic.push({
              label: `${this.translate.get("547_PRICE_PER_POINT")}`,
              description: pointAmount,
            });
          }
          else {
            basic.push({
              label: `${this.translate.get("641_MINIMUM_POINTS")}`,
              description: minPoints,
            });
            pointsNumber = minPoints
          }
        }

      }
    }
    if (this.magicsService.getPricingMethod(machineID, '90_offsetVarnish')) {
      price = pointsNumber * this.settingService.pricePerPoints
      pointsNumber = 0
    }
    return {
      alerts,
      basic: basic,
      data: {},
      pointsNumber: pointsNumber,
      price: price
    };
  }
}
