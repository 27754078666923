import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ICustomers } from '../interfaces/customer';

@Pipe({
  name: 'examination'
})
export class ExaminationPipe implements PipeTransform {

  transform(customers: ICustomers, text: any, choose): boolean {
    if (_.isEmpty(customers)) {
      return false;
    }
    const find = (Object.values(customers).find(customer => customer.name === text));
    const result = text.length && !choose && !find;
    return result;
  }

}
