import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PaperSizeMagic extends MainMagics {
  public order: number = 10;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [{
    name: "sizePaper",
    type: "select",
    label: [{ text: this.translate.get("358_PAPER_SIZE") }],
    options: [],
    required: false,
  }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];
    const machineID = this.magicsService.getMachineID(template, design);
    const paperSize = _.get(values, "sizePaper", null);
    const machine = _.get(this.settingService.machines, [machineID])
    if (paperSize) {
      let basic: IMagicMessageLabelDescription[] = [];
      let index = machine.sizes.findIndex((item) => item == paperSize)
      let paperName = _.get(this.settingService.paper, ['size', paperSize, 'name'])
      if (index < 0) {
        alerts.push({
          type: "error",
          text: `${this.translate.get("358_PAPER_SIZE")} ${paperName} ${this.translate.get("695_IS_NOT_SUPPORTED_IN_THE_MACHINE")} ${machine.name}`,
        });
      }
      return {
        basic: basic,
        data: {},
        alerts
      };
    }
  }
}
