import { Injectable } from '@angular/core';
import { SettingService } from './setting.service';
import * as _ from 'lodash';
import { IMagicsInput } from '../interfaces/magics';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class InputsService {

  constructor(private settingService: SettingService, private dataService: DataService) {
  }

  public getSelectMachineInput(input: IMagicsInput, extension, size) {
    let options = [];

    for (const machineID in this.settingService.machines) {
      const machine = this.settingService.machines[machineID];
      let machineTrue = true;
      if (input.filter) {
        for (let j = 0; j < input.filter.length; j++) {
          machineTrue = true
          switch (input.filter[j].key) {
            case 'parameter':
              if (!machine.parameters[input.filter[j].value]) {
                machineTrue = false;
              }
              break;

            default:
              break;
          }
          if (machineTrue)
            break;
        }
        if (machineTrue) {
          options.push({ value: machineID, label: machine.name });
        }
      }
    }
    let defaultValue;
    const defaultMachine = this.dataService.getDefaultValue('machineID', size)
    if (defaultMachine) {
      defaultValue = defaultMachine
    }
    else if (options.length > 0) {
      defaultValue = options[0].value
    }

    return {
      options,
      defaultValue,
    }
  }

  public getSelectSizePaperInput(machineID, size) {
    let options = [];
    let defaultValue;
    let sizes = this.settingService.paper.size
    let dis;
    let index = 0;
    if (machineID) {
      options = this.settingService.machines[machineID]
        .sizes.map((size) => {
          const label = this.settingService.getPaperSizeName(size);
          return { value: size, label };
        });

      const defaultSize = this.dataService.getDefaultValue('sizeID', size)
      if (defaultSize)
        defaultValue = parseInt(defaultSize)
      else if (options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          let option = sizes[options[i].value]
          if ((option.height >= size.height) &&
            (option.width >= size.width)) {
            const difference = (option.height - size.height) + (option.width - size.width)
            if (!dis || (difference < dis)) {
              dis = difference
              index = i
            }
          }
        }
        defaultValue = parseInt(options[index].value)
      }
    }
    else {
      for (const sizeID in this.settingService.paper.size) {
        options.push({
          value: sizeID,
          label: this.settingService.getPaperSizeName(sizeID)
        })
      }
    }

    return {
      options,
      defaultValue,
    }
  }

  public getSelectTypePaperInput(machineID, size) {
    let options = [];
    let defaultValue;
    let types = this.settingService.paper.type
    const activeTypes = _.get(this.dataService.products, [size.product.id, 'defaultValue', 'activeTypes'], null)
    if (machineID) {
      if (activeTypes) {
        this.settingService.machines[machineID]
          .types.map((type) => {
            if (activeTypes[type]) {
              options.push({ value: type, label: this.settingService.getPaperTypeName(type) })
            }
          });
      }
      else {
        options = this.settingService.machines[machineID]
          .types.map((type) => {
            return { value: type, label: this.settingService.getPaperTypeName(type) };
          });
      }
      const defaultType = this.dataService.getDefaultValue('typeID', size)
      if (defaultType)
        defaultValue = defaultType

      else if (options.length > 0) {
        let index = 0
        for (let i = 0; i < options.length; i++) {
          if ((types[options[i].value].addPercentagePoints == undefined) || types[options[i].value].addPercentagePoints == 0) {
            index = i;
            break;
          }

        }
        defaultValue = options[index].value
      }
    }
    else {
      for (const typeID in this.settingService.paper.type) {
        if (activeTypes) {
          if (activeTypes[typeID]) {
            options.push({
              value: typeID,
              label: this.settingService.getPaperTypeName(typeID)
            })
          }
        }
        else {
          options.push({
            value: typeID,
            label: this.settingService.getPaperTypeName(typeID)
          })
        }
      }
    }

    return {
      options,
      defaultValue,
    }
  }

  public getSelectThicknessPaperInput(machineID, size) {
    let options = [];
    let defaultValue;

    const thicknesseData = this.settingService.getThickness();
    const activeThicknes = _.get(this.dataService.products, [size.product.id, 'defaultValue', 'activeThicknes'], null)

    if (machineID) {
      if (activeThicknes) {
        this.settingService.machines[machineID]
          .thicknesses.map((thicknesse) => {
            if (activeThicknes[thicknesse]) {
              options.push({ value: thicknesse, label: thicknesseData[thicknesse] })
            }
          });
      }
      else {
        options = this.settingService.machines[machineID].thicknesses.map((thicknesse) => {
          return { value: thicknesse, label: thicknesseData[thicknesse] };
        });
      }

      const defaultThicknes = this.dataService.getDefaultValue('thicknesID', size)
      if (defaultThicknes)
        defaultValue = parseInt(defaultThicknes)

      else if (options.length > 0) {
        defaultValue = parseInt(options[0].value)
      }
    }
    else {
      for (const thicknessID in this.settingService.paper.thickness) {
        if (activeThicknes) {
          if (activeThicknes[thicknessID]) {
            options.push({
              value: thicknessID,
              label: thicknesseData[thicknessID]
            })
          }
        }
        else {
          options.push({
            value: thicknessID,
            label: thicknesseData[thicknessID]
          })
        }
      }
    }

    return {
      options,
      defaultValue,
    }
  }

  public getCheckboxColorfulInput(machineID, size) {
    let defaultValue = true;
    if (machineID) {
      const defaultColorful = this.dataService.getDefaultValue('colorful', size)
      if (defaultColorful)
        defaultValue = defaultColorful

      else {
        defaultValue = true
      }
    }

    return {
      defaultValue
    }
  }

  public getCheckboxTwoSidesInput(machineID, size) {
    let defaultValue;
    if (machineID) {
      const defaultTwoSides = this.dataService.getDefaultValue('twoSides', size)
      if (defaultTwoSides)
        defaultValue = defaultTwoSides

      else {
        defaultValue = false
      }
    }

    return {
      defaultValue
    }
  }
}
