import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as data from '../../assets/data/inputs/concat-details.json';
import { AuthService } from '../auth.service';
import { IAllCustomers, ICustomers } from '../interfaces/customer';
import { IResponse } from '../interfaces/general';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private afs: AngularFirestore, private authService: AuthService) {
    this.getCostumers();

  }
  customers: IAllCustomers = {};
  load$: any = new Subject();

  getContactInformation() {
    return data['default'];

  }
  deleteCostumer(id) {
    delete this.customers[id]
    this.afs.collection('customers').doc(id).delete();

  }
  async getCostumers() {
    if (!_.isEmpty(this.customers)) {
      return;
    }
    this.afs
      .collection('customers')
      .snapshotChanges()
      .pipe(
        map((actions: any) => {
          actions.map((a) => {
            const data: ICustomers = a.payload.doc.data();
            const id: string = a.payload.doc.id;
            this.customers[id] = data;
          });
        })
      )
      .subscribe((customers) => {
        this.load$.next();
      });
  }
  async addCostumer(properties: ICustomers) {

    try {
      // const result : any =  await this.authService.addMail(properties.concat.mail[0], properties.concat.phone, properties.name)
      // console.log("result",result);
    } catch (error) {
      
    }

    const customer = await this.afs.collection('customers').add(properties);
    this.customers[(customer as any)] = properties;

  }
  async saveCostumer() {
    await this.afs.doc('customers').update(this.customers);
  }
}
