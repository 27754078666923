import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PaperColorfulMagic extends MainMagics {
  public order: number = 10;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [{
    name: "colorful",
    type: "checkbox",
    label: [
      {
        text: this.translate.get("10_colorfulPrinting"),
      },
    ],
    required: false,
  }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];

    const colorful = _.get(values, "colorful", null);
    const machineID = this.magicsService.getMachineID(template, design);
    const machine = _.get(this.settingService.machines, [machineID])
    if (colorful) {
      let basic: IMagicMessageLabelDescription[] = [];
      const color = _.get(machine, ['parameters', '10_colorfulPrinting'])
      if (!color) {
        alerts.push({
          type: "error",
          text: `${this.translate.get("696_COLORFUL_PRINTING")} ${this.translate.get("697_IS_NOT_SUPPORTED_IN_THE_MACHINE")} ${machine.name}`,
        });
      }
      return {
        basic: basic,
        data: {},
        alerts
      };
    }
  }
}
