import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PaperTwoSidesMagic extends MainMagics {
  public order: number = 10;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [{
    name: "twoSided",
    type: "checkbox",
    label: [
      {
        text: this.translate.get("538_TWOSIDED"),
      },
    ],
    required: false,
  }
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];

    const twoSided = _.get(values, "twoSided", null);
    if (twoSided) {
      let basic: IMagicMessageLabelDescription[] = [];
      
      return {
        basic: basic,
        data: {},
        alerts
      };
    }
  }
}
